.content {
    padding: 0px 0px 0px 0px;
    text-align: left;
    font-size: 14px;
    color: rgba(249, 252, 255, 0.75);
    height: 65px;
    max-height: 65px;
}
.mapItemContainer {
    background-color: #464A4F;
    height: 82px;
    margin-bottom: 5px;
    margin-top: -28px;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
    box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.selected {
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), linear-gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B;
}
.bigProp {
    font-size: 16px;
    color: #EAEBEB;
    padding-left: 3px;
    /* padding-top: 5px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    min-width: 150px;
}

.thumbnail {
    height: 62px;
    width: 62px;
    margin-left: 10% !important;
}


img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.statusTab {
    width: 8px;
    box-shadow: 0px 8px 10px 1px rgba(30, 30, 32, 0.14), 0px 3px 14px 2px rgba(30, 30, 32, 0.12), 0px 5px 5px -3px rgba(30, 30, 32, 0.2);
    height: 80px;
}


.contentData {
    padding-left: 8px;
    padding-top: 4px;
    height: 80px;
}

.gridText {
    /* padding-top: 3px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.iconInLine {
    padding-right: 3px !important;
    width: 48px !important;
    /* position: relative;
	top: 3.2px; */
}

.gridRow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.sessionLabel {
    max-width: 180px;
    min-width: 180px;
    padding-left: 3px;
    padding-top: 5px;
    /* padding-bottom: 3px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.linkAvatar {
    max-height: 25.6px;
    max-width: 25.6px;
    padding-left: 40%;
    padding-bottom: 20%;
}

.disabledText {
}


.text {
    color: rgba(249, 252, 255, 0.75);
}
